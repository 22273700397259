export const generic_backend_rails_event_log = window.env.GENERIC_BACKEND_RAILS_EVENT_LOG;
export const generic_backend_rails_event_log_app_token = window.env.GENERIC_BACKEND_RAILS_EVENT_LOG_APP_TOKEN;

export const projects_names = window.env.GENERIC_FRONTEND_REACTJS_LOG_PROJECTS;

export const app_signal_monitoring_key = window.env.GENERIC_FRONTEND_REACTJS_LOG_APPSIGNAL_MONITORING_KEY || null;

export const event_log_encrypt_key = window.env.GENERIC_FRONTEND_REACTJS_LOG_ENCRYPT_KEY;
export const projects_locales = window.env.GENERIC_FRONTEND_REACTJS_LOG_LOCALES_PROJECTS;
export const projects_fonts = window.env.GENERIC_FRONTEND_REACTJS_LOG_FONTS_PROJECTS.toLowerCase();
export const ws_url = window.env.GENERIC_BACKEND_RAILS_EVENT_LOG_WEBSOCKET;
