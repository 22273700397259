let pt = {
     translation: {
          'Not found resource': 'Recurso não encontrado',
          'Terms and Conditions': 'Termos e Condições',
          'of use': 'de uso',
          'System error': 'Erro no sistema',
          'Sorry, it seems we have an unexpected error': 'Desculpe, um erro inesperado parece ter ocorrido',
          'Not found recource': 'Recurso não encontrado',
          'Sorry, it seems the requested resource does not exists or is temporally off': 'Desculpe, parece que o recurso solicitado não existe ou está temporariamente indisponível',
          'Access denied': 'Acesso negado',
          'Sorry, it seems your user account does not have access to the requested resource': 'Desculpe, parece que sua conta de usuário não tem acesso ao recurso solicitado',
          'Accept Terms and Conditions': 'Aceitar Termos e Condições',
          'Help and Technical Support': 'Ajuda e Suporte Técnico',
          'Help & Support': 'Ajuda & Suporte',
          'Cookies': 'Cookies',
          'Privacy': 'Privacidad',
          'Terms': 'Termos',
          'Events Logs': 'Registro de Vestígios',
          'English': 'Inglês',
          'Spanish': 'Espanhol',
          'Portuguese': 'Português',
          'Change language': 'Mudar idioma',
          'Light / Dark interface': 'Interface Claro / Escuro',
          "Search": "Olhe para",
          "Support Options": "Opções de suporte",
          'DateTime': 'Data e hora',
          'Date': 'Data',
          'Time': 'Hora',
          'User': 'Usuário',
          'Name': 'Nome',
          'Location': 'Ubicación',
          'Description': 'Descrição',
          'Update profile': 'Atualizar perfil',
          'Action': 'Açao',
          'No records found': 'Sem registros encontrados',
          'No more details': 'Não há mais detalhes',
          'Show more records': 'Mostrar mais registros',
          'Filter': 'Filtro',
          'Close': 'Desligar',
          'Username': 'Nome de usuário',
          'User name': 'Nome de usuário',
          'Country': 'País',
          'Country code': 'Código do país',
          'State': 'Estado',
          'State code': 'Código do estado',
          'From date': 'Data inicial',
          'To date': 'Data final',
          'User Role': 'Papel do usuário',
          'User Id': 'ID do usuário',
          'Invalid Date Format': 'Formato de data inválido',
          'Search by username': 'Buscar por nome de usuário',
          'Search by text': 'Buscar por texto',
          'The operation was successful': 'A operação foi bem sucedida',
          'The operation could not be completed. Offline data is shown': 'A operação não pode ser completada. Os dados off-line são mostrados',
          'Online': 'Conectado',
          'Offline': 'Sem conexão',
          'Update available. Do you want to reload?': 'Atualização disponível. Você quer atualizar?',
          'Today': 'Hoje',
          'Print Event Logs': 'Imprimir bitácora de ações',
          'Yesterday': 'Ontem',
          'Reset': 'Reiniciar',
          'Scope': 'Escopo',
          'Source': 'Origen',
          'recent': 'pronto',
          'today': 'hoje',
          'yesterday': 'ontem',
          '2 days ago': '2 dias atrás',
          '3 days ago': '3 dias atrás',
          '4 days ago': '4 dias atrás',
          '5 days ago': '5 dias atrás',
          'WebSocket connection closed': 'Conexão WebSocket fechada',
          'Extra data': 'Dados extras',
          'Ocurrences': 'Ocorrências',
          'Last 7 Days': 'Últimos 7 Días',
          'Last 3 Months': 'Últimos 3 Meses',
          'Last 24 Hours': 'Últimas 24 Horas',
          "Last 6 Months": 'Últimos 6 Meses',
          "Last Month": 'Mês passado',
          "Last Year": 'Ano passado',
          'Day': 'Dia',
          "Compare to previous 7 days": 'Compare com os 7 dias previstos',
          "Compare to previous 24 hours": 'Comparar com as 24 horas anteriores',
          "Compare to previous 3 months": 'Comparar com os 3 meses anteriores',
          "Compare to previous 6 months": 'Comparar com os 6 meses anteriores',
          "Compare to previous Year": 'Comparar com o ano anterior',
          "Compare to previous month": 'Comparar com o mês anterior',
          '24 Hours': '24 Horas',
          '7 Days': '7 Dias',
          '3 Month': '3 Meses',
          'Jan': 'Jan',
          'Feb': 'Fev',
          'Mar': 'Mar',
          'Apr': 'Abr',
          'May': 'Mai',
          'Jun': 'Jun',
          'Jul': 'Jul',
          'Aug': 'Ago',
          'Sep': 'Set',
          'Oct': 'Out',
          'Nov': 'Nov',
          'Dec': 'Dez',
          'Log Details': 'Detalhes do registro',
          'Live': 'Ao vivo',
          'Paused': 'Pausado',
          'Service': 'Serviço',
          'Method': 'Método',
          'Endpoint': 'Acesso',
          'Headers': 'Cabeçalhos',
          'Parameters': 'Parâmetros',
          'Response code': 'Código de resposta',
          'Response': 'Resposta',
          'Text': 'Texto'
     }
}

export default pt;
