let es = {
    translation: {
        'Not found resource': 'Recurso no encontrado',
        'Terms and Conditions': 'Términos y Condiciones',
        'of use': 'de uso',
        'System error': 'Error del sistema',
        'Sorry, it seems we have an unexpected error': 'Lo sentimos, parece que ha ocurrido un error inesperado',
        'Not found recource': 'Recurso no encontrado',
        'Sorry, it seems the requested resource does not exists or is temporally off': 'Lo sentimos, parece que el recurso solicitado no existe o no está disponible temporalmente',
        'Access denied': 'Acceso denegado',
        'Sorry, it seems your user account does not have access to the requested resource': 'Lo sentimos, parece que su cuenta de usuario no tiene acceso al recurso solicitado',
        'Accept Terms and Conditions': 'Aceptar Términos y Condiciones',
        'Help and Technical Support': 'Ayuda y Soporte Técnico',
        'Help & Support': 'Ayuda & Soporte',
        'Cookies': 'Cookies',
        'Privacy': 'Privacidad',
        'Terms': 'Términos',
        'Events Logs': 'Registro de Trazas',
        'English': 'Inglés',
        'Spanish': 'Español',
        'Portuguese': 'Portugués',
        'Change language': 'Cambiar idioma',
        'Light / Dark interface': 'Interfaz Clara / Oscura',
        "Search": "Buscar",
        "Support Options": "Opciones de Soporte",
        'DateTime': 'Fecha Hora',
        'Date': 'Fecha',
        'Time': 'Hora',
        'User': 'Usuario',
        'Name': 'Nombre',
        'Location': 'Ubicación',
        'Description': 'Descripción',
        'Update profile': 'Actualizar perfil',
        'Action': 'Acción',
        'No records found': 'No se encontraron registros',
        'No more details': 'No hay más detalles',
        'Show more records': 'Mostrar más registros',
        'Filter': 'Filtrar',
        'Close': 'Cerrar',
        'Username': 'Nombre de usuario',
        'User name': 'Nombre del usuario',
        'Country': 'País',
        'Country code': 'Código de país',
        'State': 'Estado',
        'State code': 'Código de estado',
        'From date': 'Desde la fecha',
        'User Role': 'Rol del Usuario',
        'User Id': 'Id del Usuario',
        'To date': 'Hasta la fecha',
        'Invalid Date Format': 'Formato de fecha no válido',
        'Search by username': 'Buscar por nombre de usuario',
        'Search by text': 'Buscar por texto',
        'The operation was successful': 'La operación fue satisfactoria',
        'The operation could not be completed. Offline data is shown': 'No se pudo completar la operación. Se muestran datos offline',
        'Online': 'Conectado',
        'Offline': 'Sin conexión',
        'Update available. Do you want to reload?': 'Actualización disponible. ¿Desea actualizar?',
        'Today': 'Hoy',
        'Yesterday': 'Ayer',
        'Reset': 'Reiniciar',
        'Scope': 'Ámbito',
        'Source': 'Origen',
        'recent': 'reciente',
        'today': 'hoy',
        'Print Event Logs': 'Imprimir bitácora de acciones',
        'yesterday': 'ayer',
        '2 days ago': 'hace 2 días',
        '3 days ago': 'hace 3 días',
        '4 days ago': 'hace 4 días',
        '5 days ago': 'hace 5 días',
        'WebSocket connection closed': 'Conexión WebSocket cerrada',
        'More details': 'Más detalles',
        'Ocurrences': 'Ocurrencias',
        'Last Week': 'Última semana',
        'Last 3 Months': 'Últimos 3 meses',
        'Last 24 hours': 'Últimas 24 horas',
        "Last 6 Months": 'Últimos 6 meses',
        "Last Month": 'Último mes',
        "Last Year": 'Último año',
        'Day': 'Día',
        "Compare to previous 7 days": 'Comparar con los 7 días anteriores',
        "Compare to previous 24 hours": 'Comparar con las 24 horas anteriores',
        "Compare to previous 3 months": 'Comparar con los 3 meses anteriores',
        "Compare to previous 6 months": 'Comparar con los 6 meses anteriores',
        "Compare to previous Year": 'Comparar con el año anterior',
        "Compare to previous month": 'Comparar con el mes anterior',
        '24 Hours': '24 Horas',
        '7 Days': '7 Días',
        '3 Month': '3 Meses',
        'Jan': 'Ene',
        'Feb': 'Feb',
        'Mar': 'Mar',
        'Apr': 'Abr',
        'May': 'May',
        'Jun': 'Jun',
        'Jul': 'Jul',
        'Aug': 'Ago',
        'Sep': 'Sep',
        'Oct': 'Oct',
        'Nov': 'Nov',
        'Dec': 'Dic',
        'Log Details': 'Detalles del Registro',
        'Live': 'En vivo',
        'Paused': 'Pausado',
        'Service': 'Servicio',
        'Method': 'Método',
        'Endpoint': 'Acceso',
        'Headers': 'Encabezados',
        'Parameters': 'Parámetros',
        'Response code': 'Código de respuesta',
        'Response': 'Respuesta',
        'Text': 'Texto'
    }
}

export default es;
